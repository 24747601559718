import React from "react";

const Contact = () => {
  return (
    <div id="contact" className="mt-12 ">
      <div className="flex flex-col-reverse sm:flex-row">
        <div className="flex sm:w-[65%]">
          <img className="" src="/contact.jpg" alt="contact" />
        </div>
        <div className="flex flex-col justify-center sm:w-[30%]">
          <div className="flex justify-center ">
            <h1 className=" font-bold text-[32px] font-serif text-[#f48c06] border-b-2 border-[#f48c06] ">
              Контакт
            </h1>
          </div>
          <div className="flex flex-col m-4 bg-[#264653] justify-start shadow-2xl  ">
            <div className="flex p-4 items-center m-2 gap-4">
              <img className="w-12 h-12" src="/contact1.png" alt="contact1" />
              <p className="font-bold text-white ">
                Кеј 8ми Ноември, број 8, Струга
              </p>
            </div>
            <div className="flex p-4 items-center m-2 gap-4">
              <img className="w-12 h-12" src="/contact2.png" alt="contact2" />
              <div className="flex flex-col justify-center items-center gap-2">
                <p className="font-bold text-white">+389 75/555-477</p>
                <p className="font-bold text-white">+389 46/616-477</p>
              </div>
            </div>
            <div className="flex p-4 items-center m-2 gap-4">
              <img className="w-12 h-12" src="/contact3.png" alt="contact3" />
              <p className="font-bold text-white ">advsaloski@yahoo.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
