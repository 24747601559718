import React from "react";

const OurTeam = () => {
  return (
    <div className="flex flex-col mt-[80px]">
      <div className="flex flex-col-reverse w-full  sm:flex-row sm:ml-40">
        <div className="w-[100%] h-[620px] sm:w-[30%] flex justify-start">
          <img
            className=" w-[100%] h-[100%] p-2"
            src="/team1.jpg"
            alt="team1"
          />
        </div>
        <div className="flex flex-col justify-center items-center p-2  w-[100%]  sm:w-[50%] sm:h-[740px]">
          <div className="flex flex-col justify-center items-center mb-[155px]">
            <h1 className="font-bold font-serif  text-[32px]">
              М-р Шериф Салоски
            </h1>
            <h2 className="font-semibold font-serif text-[24px] text-[#f48c06] opacity-[0.8]">
              Адвокат
            </h2>
            <p className="font-serif font-medium p-2 ml-10">
              Правни студии ги има завршено на Универзитетот „Св. Кирил и
              Методиј“ на Правниот факултет „Јустинијан Први“ во Скопје. <br />
              Дипломирал 2009 година, магистрирал казнено право во 2012 година.{" "}
              <br />
              По дипломирањето започнува со работа како практикант во Адвокатска
              канцеларија во Скопје.
              <br />
              Во 2014 година започнува како практикант во Основен Суд Струга.
              <br />
              <p>&nbsp;</p>
              Во 2015 година ја основа Адвокатска канцеларија Шериф Салоски.{" "}
              <br />
              <p>&nbsp;</p>
              Адвокатска канцеларија Шериф Салоски е овластен регистрационен
              агент при Централен регистар на Р. Северна Македонија.
            </p>
          </div>
        </div>
      </div>
      <div id="ourteam" className="flex justify-center mt-10">
        <h1 className=" font-bold text-[32px] font-serif text-[#f48c06] border-b-2 border-[#f48c06] ">
          Нашиот Тим
        </h1>
      </div>
      <div className="flex flex-col gap-40 justify-center sm:flex-row">
        <div className="flex items-center justify-center min-h-screen ">
          <div className="overflow-hidden w-[350px]  cursor-pointer rounded-xl relative group">
            <div className="rounded-xl z-50 opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out cursor-pointer absolute from-black to-transparent bg-gradient-to-t inset-x-0 -bottom-2 pt-30 text-white flex items-end">
              <div>
                <div className="  p-4 space-y-3 text-xl group-hover:opacity-100 group-hover:translate-y-0 translate-y-4 pb-10 transform transition duration-300 ease-in-out">
                  <div className="font-bold bg-[#264653] p-1">
                    М-р Хазрета Салоска
                  </div>
                  <div className="font-semibold text-[16px] bg-[#f48c06] w-[100%] p-1">
                    АДВОКАТСКИ СТРУЧЕН СОРАБОТНИК
                  </div>
                  <div className="opacity-100 text-sm  ">
                    Дипломирала правни студии во 2009, магистрирала деловно
                    право во 2013 година. Од 2015 година е вработена како
                    стручен соработник во Адвокатска канцеларија Шериф Салоски,
                    специјализирана е во областа на граѓанско и деловно право.
                  </div>
                </div>
              </div>
            </div>
            <img
              alt=""
              className="object-cover w-full  group-hover:scale-110 transition duration-300 ease-in-out"
              src="/team2.jpg"
            />
          </div>
        </div>
        <div className="flex items-center justify-center">
          <div className="overflow-hidden w-[350px] cursor-pointer rounded-xl relative group">
            <div className="rounded-xl z-50 opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out cursor-pointer absolute from-black/80 to-transparent bg-gradient-to-t inset-x-0 -bottom-2 pt-30 text-white flex items-end">
              <div>
                <div className="  p-4 space-y-3 text-xl group-hover:opacity-100 group-hover:translate-y-0 translate-y-4 pb-10 transform transition duration-300 ease-in-out">
                  <div className="font-bold bg-[#264653] p-1">
                    Александра Лозаноска
                  </div>
                  <div className="font-semibold text-[16px] bg-[#f48c06] w-[60%] p-1">
                    ПРИПРАВНИК
                  </div>
                  <div className="opacity-100 text-sm ">
                    Дипломирала правни студии во 2011, запишана е на мастер
                    студии по граѓанско право. Од 2018 година е вработена како
                    приправник во Адвокатска канцеларија Шериф Салоски,
                    специјализирана е во управни постапки и постапки пред
                    Централен регистар на Р. Северна Македонија.
                  </div>
                </div>
              </div>
            </div>
            <img
              alt=""
              className="object-cover w-full  group-hover:scale-110 transition duration-300 ease-in-out"
              src="/team3.jpg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
