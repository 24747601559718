import React from "react";

const LawInfo = () => {
  return (
    <div id="lawinfo" className="mt-12">
      <div className="flex justify-center m-10">
        <h1 className=" font-bold text-[32px] font-serif text-[#f48c06] border-b-2 border-[#f48c06] ">
          Правни Области
        </h1>
      </div>
      <div className="group flex flex-col sm:flex-row  bg-[#264653] justify-start p-[42px] gap-[60px] ">
        <div className=" flex flex-col justify-center items-center hover:-translate-y-1 hover:scale-110  duration-300 cursor-pointer">
          <img className=" w-24 h-24 " src="/law1.png" alt="law1" />

          <p className="mt-4 font-bold text-white text-[24px]">
            Трговско право
          </p>
        </div>
        <div className="flex flex-col justify-center items-center hover:-translate-y-1 hover:scale-110  duration-300 cursor-pointer">
          <img className="w-24 h-24" src="/law2.png" alt="law2" />
          <p className="mt-4 font-bold text-white text-[24px]">
            Граѓанско право
          </p>
        </div>
        <div className="flex flex-col justify-center items-center hover:-translate-y-1 hover:scale-110  duration-300 cursor-pointer">
          <img className="w-24 h-24" src="/law3.png" alt="law3" />
          <p className="mt-4 font-bold text-white text-[24px]">
            Наследно право
          </p>
        </div>
        <div className="flex flex-col justify-center items-center hover:-translate-y-1 hover:scale-110  duration-300 cursor-pointer">
          <img className="w-24 h-24" src="/law4.png" alt="law4" />
          <p className="mt-4 font-bold text-white text-[24px]">Трудово право</p>
        </div>
        <div className="flex flex-col justify-center items-center hover:-translate-y-1 hover:scale-110  duration-300 cursor-pointer">
          <img className="w-24 h-24" src="/law5.png" alt="law5" />
          <p className="mt-4 font-bold text-white text-[24px]">Семејно право</p>
        </div>
        <div className="flex flex-col justify-center items-center hover:-translate-y-1 hover:scale-110  duration-300 cursor-pointer">
          <img className="w-24 h-24" src="/law6.png" alt="law6" />
          <p className="mt-4 font-bold text-white text-[24px]">
            Работни односи
          </p>
        </div>
      </div>
    </div>
  );
};

export default LawInfo;
