import About from "./components/About";
import Contact from "./components/Contact";
import Hero from "./components/Hero";
import LawInfo from "./components/LawInfo";
import Navbar from "./components/Navbar";
import OurTeam from "./components/OurTeam";
import Footer from "./components/Footer";
import "./App.css";

function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <About />
      <OurTeam />
      <LawInfo />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
