import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const About = () => {
  return (
    <div
      id="about"
      className=" mt-[100px] flex flex-col-reverse sm:flex-row-reverse shadow-md "
    >
      <div className="flex flex-col w-full mt-[20px] sm:w-[50%] sm:p-2 ">
        <Slide autoplay={true}>
          <div className="each-slide-effect">
            <div
              className="flex items-center justify-center bg-cover h-[450px]"
              style={{
                backgroundImage: "url(/bg1.jpg)",
              }}
            ></div>
          </div>
          <div className="each-slide-effect">
            <div
              className=" items-center justify-center bg-cover h-[450px]"
              style={{
                backgroundImage: "url(/bg2.jpg)",
              }}
            ></div>
          </div>
          <div className="each-slide-effect">
            <div
              className="flex items-center justify-center bg-cover h-[450px]"
              style={{
                backgroundImage: "url(/bg3.jpg)",
              }}
            ></div>
          </div>
        </Slide>
      </div>
      <div className="flex flex-col justify-center items-center p-2 ">
        <h1 className=" font-bold text-[32px]  font-serif text-[#f48c06] border-b-2 border-[#f48c06]   ">
          За Нас
        </h1>
        <p className=" font-serif text-[18px] mt-12">
          Адвокатската канцеларија Адвокат Шериф Салоски е со седиште во Струга.
          <br />
          Основач на адвокатската канцеларија е адвокат м-р Шериф Салоски.
          <br />
          Посветени сме во обезбедување на сеопфатни правни услуги на правни и
          <br />
          физички лица од земјава и странство како и заштита на нивните права и
          интереси.
        </p>
      </div>
    </div>
  );
};

export default About;
