import React from "react";
import { Link } from "react-scroll";

const Footer = () => {
  return (
    <div className="mt-12">
      <div className=" bg-[white] w-full border-t-4 border-[#264653] opacity-90 ">
        <div className="flex flex-col-reverse gap-12 sm:flex-row justify-between p-6">
          <Link
            to="home"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
            cursor-pointer
          >
            <img className="cursor-pointer" src="/logo.png" alt="logo" />
          </Link>
          <div>
            <h1 className=" font-bold text-[20px] font-serif text-[#f48c06] border-b-2 border-[#f48c06] w-[90px] ">
              Препознатливост
            </h1>
            <p className="font-medium mt-2">
              Она што не прави препознатливи и различни од другите адвокатски
              <br />
              канцеларии е примената на висока корпоративна култура во <br />
              работењето, со практикување на високи морални и етички вредности.
              <br />
              Нашата основна мисија е задоволен и среќен клиент, што може да се
              <br />
              постигне само со посветено работење и грижа за интересите на
              клиентот.
            </p>
          </div>
          <div className="flex flex-col gap-5 mt-2">
            <h1 className="font-bold text-[20px] font-serif text-[#f48c06] border-b-2 border-[#f48c06] w-[80px]">
              Консултации
            </h1>
            <div className="flex gap-2">
              <img className="w-6" src="/contact1.png" alt="contact1" />
              <p>Кеј 8ми Ноември, број 8, Струга</p>
            </div>
            <div className="flex gap-2">
              <img
                className="w-6 h-6 justify-center flex"
                src="/contact2.png"
                alt="contact3"
              />
              <p>
                +389 75/555-477
                <br /> +389 46/616-477
              </p>
            </div>
            <div className="flex gap-2">
              <img className="w-6" src="/contact3.png" alt="contact3" />
              <p>advsaloski@yahoo.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
