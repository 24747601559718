import React from "react";

import { Link } from "react-scroll";

const Hero = () => {
  return (
    <div id="home" className="w-full h-[100vh] shadow-md">
      <div className="absolute top-0 left-0 w-full h-full bg-[#000000cc]/[0.5]" />
      <video
        src="/videoHero.mp4"
        autoPlay
        loop
        muted
        className="w-full h-full object-cover"
      />
      <div className="absolute w-[100%] h-[100%] top-0 flex flex-col justify-center items-center text-white ">
        <p className="font-semibold ml-4 text-[20px] sm:text-[24px]">
          IUSTITIA EST CONSTANS ET PERPETUA VOLUNTAS IUS SUUM CUIQUE TRIBUENDI{" "}
        </p>
        <p className="font-semibold ml-4 text-[18px] sm:text-[24px]">
          Праведноста е постојана и непрекината волја на секого да му се даде
          неговото право.
        </p>
      </div>
      <div className="absolute w-[100%] h-[100%] top-[220px] flex justify-center items-center text-white sm:top-20">
        <Link to="contact" spy={true} smooth={true} offset={50} duration={500}>
          <button
            type="button"
            className="bg-[#f48c06] p-2 font-bold w-[120px] rounded-[20px] hover:bg-transparent hover:border-2"
          >
            Контакт
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Hero;
